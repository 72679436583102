// src/components/ProductDetailsModal.js
import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

const ProductDetailsModal = ({ show, onHide, product }) => {
  if (!product) return null;

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>商品詳細</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <img src={product.image} alt={product.name} style={{ width: '100%' }} lazy />
          </Col>
          <Col>
            <h4>{product.name}</h4>
            <p>価格: {product.price.toLocaleString()}円</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>次へ</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductDetailsModal;