import { useState, useEffect } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PasswordModal from "../../components/PasswordModal";
import Loader from "../../components/Loader";
import {
    useVerifyCurrentShopOwnerPasswordMutation,
    useUpdateMyShopOwnerProfileMutation,
} from "../../slices/shopOwnersApiSlice";
import { updateProfile } from "../../slices/authSlice";

const ShopOwnerProfileScreen = () => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        postalCode: "",
        prefecture: "",
        city: "",
        streetAddress: "",
        buildingName: "",
    });

    const [showPasswordModal, setShowPasswordModal] = useState(false);

    const { userInfo } = useSelector((state) => state.auth); // auth スライスにShopOwner情報が含まれていると仮定

    const [verifyCurrentPassword, { isLoading: loadingVerifyPassword }] = useVerifyCurrentShopOwnerPasswordMutation();
    const [updateMyProfile, { isLoading: loadingUpdateProfile }] = useUpdateMyShopOwnerProfileMutation();

    useEffect(() => {
        if (userInfo) {
            setFormData({
                name: userInfo.name || "",
                email: userInfo.email || "",
                phoneNumber: userInfo.phoneNumber || "",
                postalCode: userInfo.postalCode || "",
                prefecture: userInfo.prefecture || "",
                city: userInfo.city || "",
                streetAddress: userInfo.streetAddress || "",
                buildingName: userInfo.buildingName || "",
            });
        }
    }, [userInfo]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const submitProfileHandler = async (e) => {
        e.preventDefault();
        try {
            const res = await updateMyProfile(formData).unwrap();
            dispatch(updateProfile({ name: res.name, email: res.email }));
            toast.success('プロフィールが更新されました');
        } catch (err) {
            toast.error('プロフィールの更新に失敗しました');
            console.error(err);
        }
    };

    const handleSubmitPassword = async (currentPassword, newPassword) => {
        try {
            if (currentPassword) {
                await verifyCurrentPassword({ password: currentPassword }).unwrap();
            }
            if (newPassword) {
                const res = await updateProfile({ password: newPassword }).unwrap();
                toast.success('パスワードが更新されました');
            }
            setShowPasswordModal(false);
        } catch (err) {
            toast.error('パスワードの更新に失敗しました');
        }
    };

    return (
        <Container>
            <Row className="mt-4 d-flex justify-content-center">
                <Col md={3} className="mx-4">
                    <h2>プロフィール</h2>

                    <Form onSubmit={submitProfileHandler}>
                        <Form.Group controlId="name" className="my-2">
                            <Form.Label>名前</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="名前を入力してください"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="email" className="my-2">
                            <Form.Label>メールアドレス</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="メールアドレスを入力してください"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="phoneNumber" className="my-2">
                            <Form.Label>電話番号</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="電話番号を入力してください"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="postalCode" className="my-2">
                            <Form.Label>郵便番号</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="郵便番号を入力してください (例: 100-0001)"
                                name="postalCode"
                                value={formData.postalCode}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="prefecture" className="my-2">
                            <Form.Label>都道府県</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="都道府県を入力してください (例: 東京都)"
                                name="prefecture"
                                value={formData.prefecture}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="city" className="my-2">
                            <Form.Label>市区町村</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="市区町村を入力してください (例: 千代田区)"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="streetAddress" className="my-2">
                            <Form.Label>番地</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="番地を入力してください (例: 1丁目1番1号)"
                                name="streetAddress"
                                value={formData.streetAddress}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="buildingName" className="my-2">
                            <Form.Label>建物名・部屋番号</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="建物名や部屋番号を入力してください (例: グランドタワー202)"
                                name="buildingName"
                                value={formData.buildingName}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Button
                            type="submit"
                            variant="primary"
                            className="my-2"
                        >
                            更新
                        </Button>
                        {loadingUpdateProfile && <Loader />}
                    </Form>
                </Col>
                <Col md={3}>
                    <h2>パスワード変更</h2>
                    <Button
                        variant="primary"
                        className="my-2"
                        onClick={() => setShowPasswordModal(true)}
                    >
                        パスワード変更
                    </Button>
                </Col>

                <PasswordModal 
                    show={showPasswordModal}
                    handleClose={() => setShowPasswordModal(false)}
                    handleSubmitPassword={handleSubmitPassword}
                    loadingVerifyPassword={loadingVerifyPassword}
                    loadingUpdateProfile={loadingUpdateProfile}
                />
            </Row>
        </Container>
    );
};

export default ShopOwnerProfileScreen;