// src/hooks/usePrompt.js
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

const usePrompt = (message, when) => {
    const blocker = useBlocker(when);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            const handleBlocking = () => {
                const confirmResult = window.confirm(message);
                if (confirmResult) {
                    blocker.proceed();
                } else {
                    blocker.reset();
                }
            };

            handleBlocking();
        }
    }, [blocker, message]);
};

export default usePrompt;
