import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

const CreateWithdrawalModal = ({ show, handleClose, onWithdraw, currentBalance }) => {
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');

    const maxWithdrawable = Number(currentBalance) - 750;

    const validateAmount = (value) => {
        if (value === '') {
            return '引き出し金額を入力してください。';
        }
        const numericValue = Number(value);
        if (isNaN(numericValue)) {
            return '有効な数字を入力してください。';
        }
        if (numericValue <= 0) {
            return '引き出し金額は正の数でなければなりません。';
        }
        if (numericValue > maxWithdrawable) {
            return `引き出し可能額を超えています。最大引き出し可能額は ¥${maxWithdrawable} です。`;
        }
        return '';
    };

    const handleWithdrawClick = () => {
        const validationError = validateAmount(amount);
        if (validationError) {
            setError(validationError);
            return;
        }
        setError('');
        onWithdraw({amount: Number(amount)});
        setAmount('');
    };

    const handleAmountChange = (e) => {
        const value = e.target.value;
        setAmount(value);
        if (error) {
            // 入力が変更されたらエラーを再評価
            const validationError = validateAmount(value);
            setError(validationError);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>引き出し申請</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <small>現在の残高: ¥ {currentBalance}</small> <br />
                <small>引き出し可能額: ¥ {maxWithdrawable}</small>
                {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                <Form className='mt-3'>
                    {/* <Form.Label>金額</Form.Label> */}
                    <Form.Group controlId="withdrawAmount">
                        <Form.Control
                            type="number"
                            value={amount}
                            onChange={handleAmountChange}
                            placeholder="引き出し金額を入力してください"
                            min="1"
                            max={maxWithdrawable}
                            isInvalid={!!error}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    キャンセル
                </Button>
                <Button
                    variant="primary"
                    onClick={handleWithdrawClick}
                    disabled={amount === '' || !!error}
                >
                    引き出し申請
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateWithdrawalModal;