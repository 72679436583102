import React, { useState, useEffect, useMemo } from 'react';
import { Card, Button, Container, Table, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import Tabs from '../../components/Tabs';
import OrderDetailsModal from '../../components/OrderDetailsModal';
import { useGetMyShopOrdersQuery } from '../../slices/ordersApiSlice';
import Product from '../../components/Product';
import GalleryDisplay from '../../components/GalleryDisplay';

const ShopOrderListScreen = () => {
    const { data: ordersData, isLoading, error, refetch } = useGetMyShopOrdersQuery();
    const [showModal, setShowModal] = useState(false);
    const [activeOrder, setActiveOrder] = useState(null);
    const [activeProducts, setActiveProducts] = useState([]);
    const [isRental, setIsRental] = useState(false);
    const [activeTab, setActiveTab] = useState('delivered');

    const handleShowModal = (orderId, products, isRental) => {
        setActiveOrder(orderId);
        setActiveProducts(products);
        setIsRental(isRental);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setActiveOrder(null);
        setActiveProducts([]);
        setIsRental(false);
    };

    // 未配送の商品をグループ化
    const groupedUndelivered = useMemo(() => {
        const grouped = {};
        if (ordersData) {
            const { purchaseItems, rentalItems } = ordersData;

            purchaseItems.forEach(item => {
                if (!item.isDelivered && !item.isCanceled) {
                    const orderId = item.orderId;
                    if (!grouped[orderId]) {
                        grouped[orderId] = [];
                    }
                    grouped[orderId].push({ ...item, serviceOption: 'purchase' });
                }
            });

            rentalItems.forEach(item => {
                if (!item.isDelivered && !item.isCanceled) {
                    const orderId = item.orderId;
                    if (!grouped[orderId]) {
                        grouped[orderId] = [];
                    }
                    grouped[orderId].push({ ...item, serviceOption: 'rental' });
                }
            });
        }
        return grouped;
    }, [ordersData]);

    // レンタル中の商品を取得
    const rentedItems = useMemo(() => {
        const items = [];
        if (ordersData) {
            const { rentalItems } = ordersData;
            rentalItems.forEach(item => {
                const isRentedStatus = ['rented', 'extended', 'overdue'].includes(item.status);
                if (isRentedStatus && item.isDelivered) {
                    items.push({ ...item, serviceOption: 'rental' });
                }
            });
        }
        return items;
    }, [ordersData]);

    const delivered = useMemo(() => {
        const items = {};
        if (ordersData) {
            const { purchaseItems, rentalItems } = ordersData;
            purchaseItems.forEach(item => {
                if (!item.isCanceled && item.isDelivered) {
                    if (!items[item.orderId]) {
                        items[item.orderId] = [];
                    }
                    items[item.orderId].push({ ...item, serviceOption: 'purchase' });
                }
            });
            rentalItems.forEach(item => {
                if (!item.isCanceled && item.isDelivered) {
                    if (!items[item.orderId]) {
                        items[item.orderId] = [];
                    }
                    items[item.orderId].push({ ...item, serviceOption: 'rental' });
                }
            });
        }
        return items;
    }, [ordersData]);

    const canceled = useMemo(() => {
        const items = {};
        if (ordersData) {
            const { purchaseItems, rentalItems } = ordersData;
            purchaseItems.forEach(item => {
                if (item.isCanceled) {
                    if (!items[item.orderId]) {
                        items[item.orderId] = [];
                    }
                    items[item.orderId].push({ ...item, serviceOption: 'purchase' });
                }
            });
            rentalItems.forEach(item => {
                if (item.isCanceled) {
                    if (!items[item.orderId]) {
                        items[item.orderId] = [];
                    }
                    items[item.orderId].push({ ...item, serviceOption: 'rental' });
                }
            });
        }
        return items;
    }, [ordersData]);

    const tabs = [
        { label: '配送済み', value: 'delivered', ariaSelected: activeTab === 'delivered' },
        { label: 'キャンセル', value: 'canceled', ariaSelected: activeTab === 'canceled' },
    ];

    return (
        <Container>
            <h1 className='mt-4'>注文管理ページ</h1>

            <OrderDetailsModal
                key={activeOrder}
                show={showModal}
                onHide={handleCloseModal}
                orderId={activeOrder}
                items={activeProducts}
                isRental={isRental}
                refetch={refetch}
            />

            {isLoading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error?.data?.message || error.error}</Message>
            ) : (
                <>
                {/* 未配送の商品 */}
                {Object.keys(groupedUndelivered).length > 0 && (
                    <>
                    <h3 className='alert alert-danger mt-4'>未配送の商品</h3>
                    <div className='d-flex flex-wrap gap-3 justify-content-center justify-content-md-start'>
                        {Object.entries(groupedUndelivered).map(([orderId, products]) => (
                            <Card key={orderId} className="mb-3">
                                <Card.Header style={{ cursor: 'pointer' }} onClick={() => handleShowModal(orderId, products, false)}>
                                    オーダー {orderId}
                                </Card.Header>
                                <Card.Body className='order-card'>
                                    {/* <div className='product-list shop-card'>
                                        {products.map((product) => (
                                            <Product
                                                type="shop"
                                                product={product}
                                                isAdmin={false}
                                            />
                                        ))}
                                    </div> */}
                                    <GalleryDisplay
                                        type='shop'
                                        products={products}
                                    />
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant="primary" onClick={(e) => { e.stopPropagation(); handleShowModal(orderId, products, false); }}>
                                        配送先を表示
                                    </Button>
                                </Card.Footer>
                            </Card>
                        ))}
                    </div>
                    </>
                )}

                {/* レンタル中の商品 */}
                {rentedItems.length > 0 && (
                    <>
                    <h3 className='alert alert-warning mt-4'>レンタル中の商品</h3>
                    <div className='d-flex flex-wrap gap-3 justify-content-center justify-content-md-start'>
                        {rentedItems.map((product) => (
                            <Card key={product.id} className="mb-3">
                                <Card.Header style={{ cursor: 'pointer' }} onClick={() => handleShowModal(product.orderId, [product], true)}>
                                    商品名: {product.name}
                                </Card.Header>
                                <Card.Body className='order-card'>
                                    {/* <ul className='product-list'>
                                        <Product
                                                type="shop"
                                                product={product}
                                                isAdmin={false}
                                            />
                                    </ul> */}
                                    <GalleryDisplay
                                        type='shop'
                                        products={[product]}
                                    />
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant="primary" onClick={() => handleShowModal(product.orderId, [product], true)}>
                                        レンタル情報
                                    </Button>
                                </Card.Footer>
                            </Card>
                        ))}
                    </div>
                    </>
                )}

                {/* 配送済み/キャンセルのオーダー */}
                <h3 className='alert alert-success mt-4'>配送済み/キャンセル</h3>
                <Tabs tabs={tabs} onClick={setActiveTab} />
                {activeTab === 'delivered' && (<>
                    {Object.keys(delivered).length > 0 ? (
                        <Table striped bordered hover responsive className='table-sm'>
                            <thead>
                                <tr>
                                    <th>オーダーID</th>
                                    <th>商品</th>
                                    <th>詳細</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(delivered).map(([orderId, items]) => (
                                    <tr key={orderId}>
                                        <td>{orderId}</td>
                                        <td>
                                            {items.map((item) => (
                                                <div key={item.id}>
                                                    {item.serviceOption === 'purchase' ? '購入' : 'レンタル'}: {item.name}
                                                </div>
                                            ))}
                                        </td>
                                        <td>
                                            <Button onClick={() => handleShowModal(orderId, items, false)}>
                                                詳細
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <Message variant='info'>配送済みの商品はありません</Message>
                    )}
                </>)}

                {activeTab === 'canceled' && (<>
                    {Object.keys(canceled).length > 0 ? (
                        <Table striped bordered hover responsive className='table-sm'>
                            <thead>
                                <tr>
                                    <th>オーダーID</th>
                                    <th>商品</th>
                                    <th>詳細</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(canceled).map(([orderId, items]) => (
                                    <tr key={orderId}>
                                        <td>{orderId}</td>
                                        <td>
                                            {items.map((item) => (
                                                <div key={item.id}>
                                                    {item.serviceOption === 'purchase' ? '購入' : 'レンタル'}: {item.name}
                                                </div>
                                            ))}
                                        </td>
                                        <td>
                                            <Button onClick={() => handleShowModal(orderId, items, false)}>
                                                詳細
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <Message variant='info'>キャンセルされた商品はありません</Message>
                    )}
                </>)}
                
                </>
            )}
        </Container>
    );
};

export default ShopOrderListScreen;