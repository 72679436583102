// src/components/QRReader.js
import React, { useEffect, useRef, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import PropTypes from 'prop-types';

const QRReader = ({ onScanSuccess, onScanError }) => {
    const qrCodeRef = useRef(null);
    const html5QrCodeRef = useRef(null);
    const isMountedRef = useRef(true); // コンポーネントがマウントされているかを追跡
    const isScanningRef = useRef(false); // スキャン状態を追跡
    const [isScanning, setIsScanning] = useState(false);
    const uniqueIdRef = useRef(`reader-${Math.random().toString(36).substr(2, 9)}`); // 一意の ID を生成

    useEffect(() => {
        isMountedRef.current = true;

        if (!qrCodeRef.current) {
            console.error('QRコードリーダーのDOM要素が見つかりません。');
            return;
        }

        const qrCodeSuccessCallback = (decodedText, decodedResult) => {
            onScanSuccess(decodedText, decodedResult);
            // スキャン後にスキャナーを停止したい場合は以下を有効化
            // stopScanner();
        };

        const qrCodeErrorCallback = (errorMessage) => {
            console.warn(`スキャンエラー: ${errorMessage}`);
            if (isMountedRef.current) {
                onScanError(errorMessage);
            }
        };

        const config = { fps: 5, qrbox: 250 };

        html5QrCodeRef.current = new Html5Qrcode(uniqueIdRef.current);

        Html5Qrcode.getCameras()
            .then(cameras => {
                if (cameras && cameras.length) {
                    const cameraId = cameras[0].id;
                    return html5QrCodeRef.current.start(
                        cameraId,
                        config,
                        qrCodeSuccessCallback,
                        qrCodeErrorCallback
                    );
                } else {
                    throw new Error('カメラが見つかりませんでした。');
                }
            })
            .then(() => {
                console.log('QRスキャンを開始しました。');
                isScanningRef.current = true; // スキャン開始を記録
                setIsScanning(true);
            })
            .catch(err => {
                console.error(`QRスキャンの開始に失敗: ${err}`);
                if (isMountedRef.current) {
                    onScanError(err);
                }
            });

        // クリーンアップ関数
        return () => {
            isMountedRef.current = false;
            if (html5QrCodeRef.current && isScanningRef.current) { // isScanningRef を使用
                html5QrCodeRef.current.stop()
                    .then(() => {
                        html5QrCodeRef.current.clear();
                        console.log('QRスキャンを停止しました。');
                        isScanningRef.current = false; // スキャン停止を記録
                        setIsScanning(false);
                    })
                    .catch(err => {
                        console.error(`QRスキャンの停止に失敗: ${err}`);
                    });
            }
        };
    }, [onScanSuccess, onScanError]); // isScanning を依存関係から除外

    return (
        <div
            id={uniqueIdRef.current}
            ref={qrCodeRef}
            className="qr-reader-container" // クラス名を追加
            style={{ width: '100%', marginBottom: '20px' }}
        ></div>
    );
};

QRReader.propTypes = {
    onScanSuccess: PropTypes.func.isRequired,
    onScanError: PropTypes.func,
};

QRReader.defaultProps = {
    onScanError: () => {},
};

export default QRReader;