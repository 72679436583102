// src/screens/shop/CheckoutCounterScreen.jsx
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Modal, Button, Row, Col, Table } from 'react-bootstrap';
import QRReader from '../../components/QRReader';
import { useCheckoutProductAtShopMutation, useCancelCheckoutProductsAtShopMutation } from '../../slices/productsApiSlice';
import { toast } from 'react-toastify';
import OrderDetailsModal from '../../components/OrderDetailsModal';
import ProductDetailsModal from '../../components/ProductDetailsModal';
import usePrompt from '../../hooks/usePrompt'; // カスタムフックのインポート
import Message from '../../components/Message';

const CheckoutCounterScreen = () => {
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const [checkoutProductAtShop, { isLoading: isCheckoutLoading }] = useCheckoutProductAtShopMutation();
    const [cancelCheckoutProductsAtShop, { isLoading: isCancelLoading }] = useCancelCheckoutProductsAtShopMutation();

    const [productDetails, setProductDetails] = useState(null);
    const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);

    const [orderDetails, setOrderDetails] = useState(null);
    const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);

    const [isCheckoutActive, setIsCheckoutActive] = useState(false); // チェックアウトのアクティブ状態
    const [showCheckoutModal, setShowCheckoutModal] = useState(false); // チェックアウトモーダルの表示状態

    // カートに製品を追加する関数
    const addToCart = (product) => {
        setCart(prevCart => {
            const existingProduct = prevCart.find(item => item.id === product.id);
            if (existingProduct) {
                toast.info('この商品はすでにカートに追加されています。');
                return prevCart;
            } else {
                return [...prevCart, { ...product }];
            }
        });
        setIsCheckoutActive(true); // カートに追加されたらチェックアウトを有効に
    };

    // 合計金額の計算
    useEffect(() => {
        const newTotal = cart.reduce((sum, item) => sum + item.price, 0);
        setTotal(newTotal);
    }, [cart]);

    const isProcessingScanRef = useRef(false);

    // スキャン成功時のハンドラー
    const handleScanSuccess = useCallback(async (decodedText, decodedResult) => {
        if (isProcessingScanRef.current) return; // 既に処理中なら無視

        isProcessingScanRef.current = true; // 処理開始

        console.log(`QRコードの内容: ${decodedText}`);

        // QRコードの形式を確認
        const regex = /^https:\/\/www\.furupura\.jp\/product\/(\d+)$/;
        const match = decodedText.match(regex);

        if (match) {
            const productId = parseInt(match[1], 10);
            console.log(`製品ID: ${productId}`);

            try {
                const response = await checkoutProductAtShop({ productId }).unwrap();

                if (response.product) {
                    // 商品をカートに追加
                    addToCart(response.product);

                    // 商品情報をモーダルで表示
                    setProductDetails(response.product);
                    setShowProductDetailsModal(true);
                    setShowCheckoutModal(false);
                } else if (response.purchaseItem || response.rentalItem) {
                    // オンライン注文が存在する場合
                    toast.error(response.message);

                    if (window.confirm('オンラインで注文が入っています。オンラインの注文をキャンセルしてこの商品をレジに追加しますか？')) {
                        window.alert('オンラインの注文の詳細を表示します');

                        const orderId = response.purchaseItem?.orderId || response.rentalItem?.orderId;
                        const items = response.purchaseItem ? [response.purchaseItem] : [response.rentalItem];
                        const isRental = false;

                        setOrderDetails({ orderId, items, isRental });
                        setShowOrderDetailsModal(true);
                        setShowCheckoutModal(false);
                    } else {
                        window.alert('オンラインの注文が入っているため、この商品をレジに追加しませんでした');
                    }
                } else {
                    console.log('Unexpected response:', response);
                    toast.error(response.message || '商品をレジに追加できませんでした。');
                    setTimeout(() => {
                        isProcessingScanRef.current = false;
                    }, 2000);
                }
            } catch (error) {
                console.error('Error during checkoutProductAtShop:', error);
                toast.error(error.data.message || '商品をレジに追加できませんでした。');
                setTimeout(() => {
                    isProcessingScanRef.current = false;
                }, 2000);
            }
        } else {
            toast.error('無効なQRコードです。正しい形式のQRコードをスキャンしてください。');
            setTimeout(() => {
                isProcessingScanRef.current = false;
            }, 2000);
        }
    }, [checkoutProductAtShop]);

    // スキャンエラー時のハンドラー
    const handleScanError = useCallback((errorMessage) => {
        console.warn(`スキャンエラー: ${errorMessage}`);
    }, []);

    // お会計を完了する関数
    const finalizeCheckout = () => {
        toast.success('お会計が完了しました！');
        setCart([]);
        setTotal(0);
        setIsCheckoutActive(false); // チェックアウトを無効に
        setShowCheckoutModal(false); // モーダルを閉じる
    };

    // お会計をキャンセルする関数
    const cancelCheckout = async () => {
        try {
            const productIds = cart.map(item => item.id);
            await cancelCheckoutProductsAtShop({ productIds }).unwrap();
            toast.success('お会計がキャンセルされました。');
            setCart([]);
            setTotal(0);
            setIsCheckoutActive(false); // チェックアウトを無効に
            setShowCheckoutModal(false); // モーダルを閉じる
        } catch (err) {
            console.error('お会計のキャンセルに失敗しました:', err);
            toast.error('お会計のキャンセル処理中にエラーが発生しました。');
        }
    };

    // `usePrompt` を使用してナビゲーションをブロック
    usePrompt(
        'お会計中です。お会計完了/キャンセルする前にページを離れると在庫管理に深刻なエラーを引き起こす可能性があります。よろしいですか？',
        isCheckoutActive
    );

    // ブラウザのタブ閉鎖やリロード時の確認
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isCheckoutActive) {
                e.preventDefault();
                e.returnValue = ''; // 一部のブラウザでは必要
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isCheckoutActive]);

    // Automatically close the ProductDetailsModal after it is shown
    useEffect(() => {
        if (showProductDetailsModal) {
            const timer = setTimeout(() => {
                setShowProductDetailsModal(false);
                setShowCheckoutModal(true);
                isProcessingScanRef.current = false; // フラグをリセット
            }, 2000); // 3秒後にモーダルを閉じる

            // Cleanup the timer if the component unmounts before the timer completes
            return () => clearTimeout(timer);
        }
    }, [showProductDetailsModal]);

    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
            <h1>レジカウンター</h1>

            {/* 「お会計を開始」ボタン */}
            <Button 
                variant="primary" 
                onClick={() => setShowCheckoutModal(true)}
                disabled={isCheckoutActive} // 既にチェックアウト中の場合は無効化
                style={{ marginBottom: '20px' }}
            >
                お会計を開始
            </Button>

            {/* 他の操作をブロックするためにお会計以外の要素はここに配置 */}
            {/* 例: 他の製品リストや情報など */}

            {/* チェックアウトモーダル */}
            <Modal
                show={showCheckoutModal}
                onHide={() => {
                    if (!isCheckoutActive) {
                        setShowCheckoutModal(false);
                    } else {
                        toast.warn('お会計中はモーダルを閉じることはできません。');
                    }
                }}
                size="lg"
                backdrop="static" // バックドロップをクリックしても閉じない
                keyboard={!isCheckoutActive} // チェックアウト中はキーボード操作で閉じない
                centered
            >
                <Modal.Header closeButton={!isCheckoutActive}>
                    <Modal.Title>お会計</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} xs={12} style={{ marginBottom: '20px' }}>
                            {showCheckoutModal && (
                                <QRReader onScanSuccess={handleScanSuccess} onScanError={handleScanError} />
                            )}
                        </Col>
                        <Col md={6} xs={12}>
                            {/* カートの表示 */}
                            <h2>カート</h2>
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>商品名</th>
                                        <th>価格</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cart?.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.name}</td>
                                            <td>{item.price.toLocaleString()}円</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            {/* 合計金額の表示 */}
                            <h3 style={{ marginTop: '20px' }}>合計金額: {total.toLocaleString()}円</h3>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button 
                        variant="outline-danger" 
                        onClick={cart.length === 0 ? () => setShowCheckoutModal(false) : cancelCheckout}
                        disabled={isCancelLoading}
                    >
                        {isCancelLoading ? 'キャンセル中...' : 'キャンセル'}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={finalizeCheckout}
                        disabled={cart.length === 0 || isCheckoutLoading}
                    >
                        {isCheckoutLoading ? '処理中...' : '確定する'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* 商品詳細モーダル */}
            <ProductDetailsModal
                show={showProductDetailsModal}
                onHide={() => {
                    setShowProductDetailsModal(false);
                    setShowCheckoutModal(true);
                    isProcessingScanRef.current = false; // モーダルを閉じたらフラグをリセット
                }}
                product={productDetails}
            />

            {/* オーダー詳細モーダル */}
            {orderDetails && 
                <OrderDetailsModal
                    show={showOrderDetailsModal}
                    onHide={() => {
                        setShowOrderDetailsModal(false);
                        setShowCheckoutModal(true);
                        isProcessingScanRef.current = false; // モーダルを閉じたらフラグをリセット
                    }}
                    orderId={orderDetails?.orderId}
                    items={orderDetails?.items}
                    isRental={orderDetails?.isRental}
                    refetch={() => {}}
                />
            }
        </div>
    );
};

export default CheckoutCounterScreen;