import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useRegisterBankAccountMutation } from '../slices/balanceApiSlice';
import { toast } from 'react-toastify';

const BankAccountModal = ({ show, handleClose, initialBankAccount }) => {
    const [bankName, setBankName] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchCode, setBranchCode] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountHolderName, setAccountHolderName] = useState('');
    const [accountType, setAccountType] = useState('普通');

    const [errors, setErrors] = useState({});

    const [registerBankAccount] = useRegisterBankAccountMutation();

    useEffect(() => {
        if (initialBankAccount) {
            setBankName(initialBankAccount.bankName || '');
            setBranchName(initialBankAccount.branchName || '');
            setBranchCode(initialBankAccount.branchCode || '');
            setAccountNumber(initialBankAccount.accountNumber || '');
            setAccountHolderName(initialBankAccount.accountHolderName || '');
            setAccountType(initialBankAccount.accountType || '普通');
        }
    }, [initialBankAccount]);

    const validateBankDetails = () => {
        const newErrors = {};

        if (!bankName.trim()) {
            newErrors.bankName = '銀行名を入力してください。';
        }
        if (!branchName.trim()) {
            newErrors.branchName = '支店名を入力してください。';
        }
        if (!/^\d{3}$/.test(branchCode)) {
            newErrors.branchCode = '支店コードは3桁の数字で入力してください。';
        }
        if (!/^\d{7,10}$/.test(accountNumber)) {
            newErrors.accountNumber = '口座番号は7〜10桁の数字で入力してください。';
        }
        if (!accountHolderName.trim()) {
            newErrors.accountHolderName = '口座名義を入力してください。';
        }
        if (!['普通', '当座'].includes(accountType)) {
            newErrors.accountType = '口座種別を選択してください。';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (validateBankDetails()) {
            try {
                await registerBankAccount({
                    bankName,
                    branchName,
                    branchCode,
                    accountNumber,
                    accountHolderName,
                    accountType,
                }).unwrap();
                toast.success('銀行口座情報が登録されました！');
                handleClose();
            } catch (error) {
                console.error('銀行口座情報の登録に失敗しました', error);
                toast.error('銀行口座情報の登録に失敗しました。再度お試しください。');
            }
        } else {
            e.stopPropagation();
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>銀行口座の登録</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="bankName" className="mb-3">
                        <Form.Label>銀行名</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="例: 三菱UFJ銀行"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                            isInvalid={!!errors.bankName}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.bankName}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="branchName" className="mb-3">
                        <Form.Label>支店名</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="例: 東京支店"
                            value={branchName}
                            onChange={(e) => setBranchName(e.target.value)}
                            isInvalid={!!errors.branchName}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.branchName}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="branchCode" className="mb-3">
                        <Form.Label>支店コード</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="例: 001"
                            value={branchCode}
                            onChange={(e) => setBranchCode(e.target.value)}
                            isInvalid={!!errors.branchCode}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.branchCode}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="accountNumber" className="mb-3">
                        <Form.Label>口座番号</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="例: 1234567"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            isInvalid={!!errors.accountNumber}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.accountNumber}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="accountHolderName" className="mb-3">
                        <Form.Label>口座名義</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="例: 山田 太郎"
                            value={accountHolderName}
                            onChange={(e) => setAccountHolderName(e.target.value)}
                            isInvalid={!!errors.accountHolderName}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.accountHolderName}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="accountType" className="mb-3">
                        <Form.Label>口座種別</Form.Label>
                        <Form.Control
                            as="select"
                            value={accountType}
                            onChange={(e) => setAccountType(e.target.value)}
                            isInvalid={!!errors.accountType}
                            required
                        >
                            <option value="普通">普通</option>
                            <option value="当座">当座</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.accountType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        登録
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default BankAccountModal;
