import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, NavLink } from 'react-router-dom';

const ShopNavigationBar = ({ nav, navFontSize, navFontColor, navFontFamily, navFontWeight, slug }) => {
  const CustomNav = () => {
    switch (nav) {
      case 1:
        return 'border-top-bottom';
      case 2:
        return 'li-separator';
      case 0:
      default:
        return 'no-decoration';
    }
  };

  return (
    <ul className={`shop-home-nav ${CustomNav()}`}
      style={{
        fontSize: navFontSize,
        fontFamily: navFontFamily,
        fontWeight: navFontWeight,
        gap: `calc(${navFontSize} / 2)`,
      }}
    >
      <li style={{ paddingRight: `calc(${navFontSize} / 2)` }}>
        <NavLink to={`/home/${slug}`} style={{color: navFontColor}} activeClassName="active">Home</NavLink>
      </li>
      <li style={{ paddingRight: `calc(${navFontSize} / 2)` }}>
        <NavLink to={`/home/${slug}/about`} style={{color: navFontColor}} activeClassName="active">About</NavLink>
      </li>
      <li>
        <NavLink to={`/home/${slug}/contact`} style={{color: navFontColor}} activeClassName="active">Contact</NavLink>
      </li>
    </ul>
  );
};

export default React.memo(ShopNavigationBar);
