import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../../components/FormContainer";
import Loader from '../../components/Loader';
import { useShopOwnerLoginMutation } from "../../slices/shopOwnersApiSlice";
import { setCredentials } from "../../slices/authSlice";
import { toast } from 'react-toastify';

const ShopLoginScreen = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [login, { isLoading }] = useShopOwnerLoginMutation();

    const { userInfo } = useSelector((state) => state.auth);

    const {search } = useLocation();
    const sp = new URLSearchParams(search);
    const redirect = sp.get('redirect') || `/`;
    const redirectUser = sp.get('redirect') || '/';

    useEffect(() => {
        if (userInfo && userInfo.role === 'shop') {
            navigate(redirect);
        } else if (userInfo) {
            navigate(redirectUser);
        }
    }, [userInfo, redirect, navigate]);

    const submitHandler = async (e) => {
        e.preventDefault()
        try {
            const res = await login({ email, password }).unwrap();
            dispatch(setCredentials({...res, }));
            navigate(redirect);
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    }
  return (
    <FormContainer>
        <h1>店舗オーナーのlogin画面</h1>

        <Form onSubmit={submitHandler}>
            <Form.Group controlId="email" className="my-3">
                <Form.Label>メールアドレス</Form.Label>
                <Form.Control   
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                >
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="password" className="my-3">
                <Form.Label>パスワード</Form.Label>
                <Form.Control   
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                >
                </Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary" className="mt-2" disabled={ isLoading }>
                ログイン
            </Button>

            { isLoading && <Loader /> }
        </Form>

        <Row className="py-3">
            <Col>
                パスワードをお忘れの方は support@furupura.co.jp までお問い合わせください
            </Col>
        </Row>
    </FormContainer>
  )
}

export default ShopLoginScreen