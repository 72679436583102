import React, { useState, useEffect } from 'react';
import { Modal, Button, Badge, Form, ListGroup } from 'react-bootstrap';
import {
    useGetOrderShippingAddressQuery,
    useDeliverOrderMutation,
    useReturnRentalProductMutation,
    useCancelOrderItemsMutation
} from '../slices/ordersApiSlice';
import { toast } from 'react-toastify';
import { formatDateRange2 } from '../utils/dateUtils';
import TextareaAutosize from 'react-textarea-autosize';
import Message from './Message';

const OrderDetailsModal = ({ show, onHide, orderId, items, isRental, refetch }) => {
    const { data: Info, isFetching, error } = useGetOrderShippingAddressQuery(orderId);
    const [deliverOrder, { isLoading: isDelivering, reset: resetDeliver }] = useDeliverOrderMutation();
    const [returnRentalProduct, { isLoading: isReturning, reset: resetReturn }] = useReturnRentalProductMutation();
    const [cancelOrderItems, { isLoading: isCancelling, reset: resetCancel }] = useCancelOrderItemsMutation();

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedReason, setSelectedReason] = useState('');
    const [cancellationReason, setCancellationReason] = useState('');

    const predefinedReasons = [
        "お客様のお支払いが完了する前に、店頭で商品が他のお客様にお買い上げされました。今回は残念でしたが、またぜひ古着探しをお楽しみください！",
        "その他",
    ];
    
    // 更新: テンプレートが選択されたらキャンセル理由を設定
    useEffect(() => {
        if (selectedReason && selectedReason !== "その他") {
            setCancellationReason(selectedReason);
        } else if (selectedReason === "その他") {
            setCancellationReason('');
        }
    }, [selectedReason]);

    const handleDeliverAllItems = async () => {
        if (Info?.status !== 'paid') {
            toast.error("支払いが完了していないため、商品の配送はできません。");
            return;
        }
        try {
            await deliverOrder({
                orderId: orderId,
                purchaseItemIds: items.filter(p => p.serviceOption === 'purchase').map(p => p.id),
                rentalItemIds: items.filter(p => p.serviceOption === 'rental').map(p => p.id)
            }).unwrap();
            toast.success("商品の配送が完了しました");
            refetch();
            onHide();
            resetDeliver(); // 配送ミューテーションの状態をリセット
        } catch (error) {
            toast.error("商品の配送処理に失敗しました。サポートにお問い合わせください。");
        }
    };

    const handleReturnProduct = async () => {
        try {
            await returnRentalProduct(items[0].id).unwrap();
            toast.success("商品の返却が完了しました");
            refetch();
            onHide();
            resetReturn(); // 返却ミューテーションの状態をリセット
        } catch (error) {
            toast.error("商品の返却処理に失敗しました。サポートにお問い合わせください。");
        }
    };

    const handleOpenCancelModal = () => {
        setSelectedItems([]);
        setShowCancelModal(true);
    };

    const handleCloseCancelModal = () => {
        setSelectedItems([]);
        setShowCancelModal(false);
    };

    const handleSelectItem = (itemId) => {
        setSelectedItems(prev => {
            if (prev.includes(itemId)) {
                return prev.filter(id => id !== itemId);
            } else {
                return [...prev, itemId];
            }
        });
    };

    const handleConfirmCancel = async () => {
        if (selectedItems.length === 0) {
            toast.warn("キャンセルする商品を選択してください。");
            return;
        }

        if (!cancellationReason.trim()) {
            toast.warn("キャンセル理由を入力してください。");
            return;
        }

        const confirm = window.confirm("選択した商品を本当にキャンセルしますか？");
        if (!confirm) return;

        const purchaseItemIds = items
            .filter(p => selectedItems.includes(p.id) && p.serviceOption === 'purchase')
            .map(p => p.id);
        const rentalItemIds = items
            .filter(p => selectedItems.includes(p.id) && p.serviceOption === 'rental')
            .map(p => p.id);

        if (purchaseItemIds.length === 0 && rentalItemIds.length === 0) {
            toast.warn("選択された商品にはキャンセル可能な商品が含まれていません。");
            return;
        }

        try {
            await cancelOrderItems({
                orderId: orderId,
                purchaseItemIds,
                rentalItemIds,
                cancellationReason: cancellationReason.trim()
            }).unwrap();
            toast.success("選択した商品のキャンセルが完了しました。");
            refetch();
            handleCloseCancelModal();
            onHide();
            resetCancel(); // キャンセルミューテーションの状態をリセット
        } catch (error) {
            toast.error("商品のキャンセル処理に失敗しました。サポートにお問い合わせください。");
        }
    };

    const inStoreItems = [
        ...items.filter(item => item.inStore) || [],
    ];
    const deliveryItems = [
        ...items.filter(item => !item.inStore) || [],
    ];

    return (
        <>
            <Modal show={show} onHide={onHide} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Order ID: {orderId}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {inStoreItems.length > 0 && (
                    <>
                        <h4>お店でお渡し</h4>
                        <ul className='px-2'>
                            {inStoreItems.map(item => (
                                <li key={item.name}>
                                    {item.name} - {item.startDate && item.endDate ? (
                                        <>
                                            ({formatDateRange2(item.startDate, item.endDate)})
                                            <Badge bg="warning" className="ms-2">レンタル</Badge>
                                        </>
                                    ) : item.useSubscription ? (<>
                                        <Badge bg="warning" className="ms-2">レンタル</Badge>
                                        <Badge bg="success" className="ms-2">サブスク</Badge>
                                    </>) : (
                                        <Badge bg="info">購入</Badge>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {deliveryItems.length > 0 && (
                    <>
                        <h4>配送</h4>
                        <ul className='px-2'>
                            {deliveryItems.map(item => (
                                <li key={item.name}>
                                    {item.name} - {item.startDate && item.endDate ? (
                                        <>
                                            ({formatDateRange2(item.startDate, item.endDate)})
                                            <Badge bg="warning" className="ms-2">レンタル</Badge>
                                        </>
                                    ) : item.useSubscription ? (<>
                                        <Badge bg="warning" className="ms-2">レンタル</Badge>
                                        <Badge bg="success" className="ms-2">サブスク</Badge>
                                    </>) : (
                                        <Badge bg="info">購入</Badge>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                    <h4>お客様の情報</h4>
                    {isFetching ? (
                        <div>Loading...</div>
                    ) : error ? (
                        <div>Error: {error.message}</div>
                    ) : Info?.status === 'pending' ? (
                        <div className="text-center text-danger">
                            <h4>支払いが完了していません</h4>
                            <p>オーダー確定から12時間以内に支払いがない場合は、自動的にオーダーがキャンセルとなります。</p>
                        </div>
                    ) : Info?.status === 'canceled' ? (
                        <div className="text-center text-danger">
                            <h4>キャンセル済み</h4>
                            <p>このオーダーはキャンセルされました。</p>
                        </div>
                    ) : (
                        <div className='px-2 pb-3'>
                            <p>名前: {Info?.name}</p>
                            <p>〒: {Info?.shippingAddress?.postalCode}</p>
                            <p>住所: {Info?.shippingAddress?.prefecture} {Info?.shippingAddress?.city} {Info?.shippingAddress?.streetAddress}</p>
                            <p>{Info?.shippingAddress?.buildingName}</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {isRental ? (
                        <Button onClick={handleReturnProduct} disabled={isReturning}>
                            返却を完了
                        </Button>
                    ) : Info?.status === 'paid' ? (<>
                        <Message>
                            お客様のお支払いが完了しているため、商品のキャンセルはできません。どうしてもキャンセルが必要な場合は、サポートにお問い合わせください。support@furupura.co.jp
                        </Message>
                        <Button onClick={handleDeliverAllItems} disabled={isDelivering || Info?.status !== 'paid'}>
                            配送完了
                        </Button>
                    </>) : Info?.status !== 'canceled' ? (
                        <Button variant="outline-danger" onClick={handleOpenCancelModal} disabled={Info?.status === 'canceled'}>
                            注文商品をキャンセル
                        </Button>
                    ) : null}
                </Modal.Footer>
            </Modal>

            {/* キャンセル確認用モーダル */}
            <Modal show={showCancelModal} onHide={handleCloseCancelModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>注文商品のキャンセル</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>キャンセルする商品を選択してください：</p>
                    <ListGroup variant="flush">
                        {items.map(item => (
                            <ListGroup.Item key={item.id}>
                                <Form.Check
                                    type="checkbox"
                                    label={
                                        <>
                                            {item.name} - {item.startDate && item.endDate ? (
                                                <>
                                                    ({formatDateRange2(item.startDate, item.endDate)})
                                                    <Badge bg="warning" className="ms-2">レンタル</Badge>
                                                </>
                                            ) : item.useSubscription ? (
                                                <>
                                                    <Badge bg="warning" className="ms-2">レンタル</Badge>
                                                    <Badge bg="success" className="ms-2">サブスク</Badge>
                                                </>
                                            ) : (
                                                <Badge bg="info">購入</Badge>
                                            )}
                                        </>
                                    }
                                    onChange={() => handleSelectItem(item.id)}
                                    checked={selectedItems.includes(item.id)}
                                />
                            </ListGroup.Item>
                        ))}
                    </ListGroup>

                    <Form.Group className="mt-3">
                        <Form.Label>キャンセル理由のテンプレート：</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedReason}
                            onChange={(e) => setSelectedReason(e.target.value)}
                        >
                            <option value="">-- テンプレートを選択できます --</option>
                            {predefinedReasons.map((reason, index) => (
                                <option key={index} value={reason}>{reason}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    {/* 編集可能なキャンセル理由の入力 */}
                    <Form.Group className="mt-3">
                        <Form.Label>キャンセル理由を記入してください：</Form.Label>
                        <TextareaAutosize
                            minRows={3}
                            className="form-control"
                            value={cancellationReason}
                            onChange={(e) => setCancellationReason(e.target.value)}
                            placeholder="キャンセル理由を入力または編集してください..."
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    { isCancelling ? <Button variant="secondary" disabled>キャンセル中...</Button> : (<>
                        <Button variant="secondary" onClick={handleCloseCancelModal}>
                            やめる
                        </Button>
                        <Button variant="danger" onClick={handleConfirmCancel} disabled={isCancelling}>
                            キャンセルを実行
                        </Button>
                    </>)}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default OrderDetailsModal;